import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { Divider, Row, Col, Form, Input, Checkbox, DatePicker, Radio, Select, Button, Modal, Spin } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import selectFilterOption from '../../lib/utils/selectFilterOption';
import { PhonesTable } from '../PhonesTable/PhonesTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useCreateApplicationContainerContext } from '../../containers/CreateApplicationContainer/CreateApplicationContainerContext';
import { CreatedApplication } from '../../lib/interfaces/CreatedApplication';
import { displayFormat } from '../../lib/utils/formatDate';
import { Address } from '../../lib/interfaces/Address';
import SuggestInput from '../SuggestInput/SuggestInput';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { getFio } from '../../lib/utils/applications';
import { SearchResultPersonModal } from './SearchResultPersonModal';
import {
  HandleSetPersonToForm,
  HandleSetCreateApplicationState,
  ICreateApplicationState,
  HandleSetCopyToForm,
} from './interfacesCreateApplication';
import './CreateApplication.less';
import { geocodingRoute } from '../../lib/routes/applications';
import lockIco from '../../lib/img/lock.svg';
import { ProjectTypes } from '../../lib/const/projectsTypes';
import { ReactComponent as PlusIcon } from '../../lib/img/plus.svg';
import { ReactComponent as DeleteIcon } from '../../lib/img/basket.svg';
import { uniqueId } from 'lodash';
import FireBaseService from '../../services/FireBaseService/FireBaseService';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';
import { setApplicationSaved } from '../../store/slices/applicationsSlice/applicationsSlice';

interface IProps {}

export const CreateApplication: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const { personCategory, appSubject, regularityOfAssistance, region, subwayStation, appType } = useAppSelector(
    (state) => state.dictionaries
  );
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { responsibles, responsiblesLoading, meUserFull, meUser } = useAppSelector((state) => state.persons);
  const { applicationCopy, applicationSaved } = useAppSelector((state) => state.applications);

  const { loadResponsibles, findDynamicSVGReactElement, hasAccess } = useMainContext();
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const { handleCreateApplication, handleSearchPerson } = useCreateApplicationContainerContext();

  const defaultCreateApplicationState: ICreateApplicationState = {
    isFormEditable: false,
    isLoadingData: false,
    activeModal: '',
  };

  const [createApplicationState, setCreateApplicationState] =
    useState<ICreateApplicationState>(defaultCreateApplicationState);

  const resetCreateApplicationState = () => {
    setCreateApplicationState(defaultCreateApplicationState);
  };

  const handleSetCreateApplicationState: HandleSetCreateApplicationState = (prop) => {
    setCreateApplicationState((prev) => ({ ...prev, ...prop }));
  };

  // Активания уведомления при редактировании адреса связанного пользователя
  // При первом изменении, пользователю выводится информационное модальное окно
  const onChangeAddress = () => {
    // console.log("onChangeAddress");
    setCreateApplicationState((prev) => {
      // console.log(prev);
      if (!Boolean(prev.selectedPerson)) return prev;
      if (prev.isAddressChanged) return prev;
      return {
        ...prev,
        activeModal: 'isAddressChanged',
        isAddressChanged: true,
      };
    });
  };

  // Активания уведомления при редактировании телефонов связанного пользователя
  // При первом изменении, пользователю выводится информационное модальное окно
  const onChangePhones = () => {
    setCreateApplicationState((prev) => {
      if (!Boolean(createApplicationState.selectedPerson)) return prev;
      if (prev.isPhoneChanged) return prev;
      return {
        ...prev,
        activeModal: 'isPhoneChanged',
        isPhoneChanged: true,
      };
    });
  };

  const disableStyle = {
    style: createApplicationState.isFormEditable ? {} : { opacity: 0.5, cursor: 'not-allowed' },
  };
  const [createApplicationForm] = Form.useForm();

  const onFormSubmit = async (values) => {
    handleSetCreateApplicationState({ isLoadingData: true });
    // Проверка адреса на корректность

    const checkAddressResult: { success: boolean; message?: string } = await axios
      .get(geocodingRoute({ address: values.unstructuredAddress }))
      .then(() => ({
        success: true,
      }))
      .catch(({ response }) => ({
        success: false,
        message: response.data.message,
      }));

    if (!checkAddressResult.success) {
      createApplicationForm.setFields([{ name: 'unstructuredAddress', errors: [''], validating: false }]);
      handleSetCreateApplicationState({ isLoadingData: false });
      return Modal.error({
        content: (
          <>
            <div>{checkAddressResult.message?.replace('. Уточните запрос', '')}</div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              Пожалуйста, выберите адрес из выпадающего списка
            </div>
          </>
        ),
        bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
      });
    }

    const checkAddressResultAdditional: { success: boolean; message?: string; unstructuredAddressValue?: string }[] =
      await values?.additionalAddress?.map(
        async (address) =>
          await axios
            .get(geocodingRoute({ address: address.unstructuredAddress }))
            .then(() => ({
              success: true,
            }))
            .catch(({ response }) => ({
              success: false,
              message: response.data.message,
              unstructuredAddressValue: address.unstructuredAddress,
            }))
      );

    const isErrorAdditionalAddress = await checkAddressResultAdditional?.find((el) => !el.success);

    if (isErrorAdditionalAddress && !isErrorAdditionalAddress?.success) {
      createApplicationForm.setFields([
        {
          name: [
            'additionalAddress',
            values?.additionalAddress?.findIndex(
              (el) => el?.unstructuredAddress === isErrorAdditionalAddress?.unstructuredAddressValue
            ),
            'unstructuredAddress',
          ],
          errors: [''],
          validating: false,
        },
      ]);

      handleSetCreateApplicationState({ isLoadingData: false });
      return Modal.error({
        content: (
          <>
            <div>{isErrorAdditionalAddress.message?.replace('. Уточните запрос', '')}</div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              Пожалуйста, выберите адрес из выпадающего списка
            </div>
          </>
        ),
        bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
      });
    }

    const address: Address = {
      unstructuredAddress: values.unstructuredAddress,
      apartment: values.apartment,
      subwayStationId: values.subwayStationId,
      intercomCode: values.intercomCode,
      floor: values.floor,
      entrance: values.entrance,
    };

    const additionalAddress: Address[] = values?.additionalAddress?.map((address) => ({
      unstructuredAddress: address.unstructuredAddress,
      apartment: address.apartment,
      subwayStationId: address.subwayStationId,
      intercomCode: address.intercomCode,
      floor: address.floor,
      entrance: address.entrance,
    }));

    // TODO Sumaev person: Person, но в первом условии передаешь только айди
    // Если пользователь связал просьбу с существующей анкетой берется эта анкета, иначе - анкета будет создана из введенных в форму дaнных
    const person: any = createApplicationState.selectedPerson
      ? { id: createApplicationState.selectedPerson.id }
      : {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          middleName: values.middleName?.trim(),
          birthDate: values.birthDate.format('YYYY-MM-DD'),
          sex: values.sex,
          regionId: values.regionId,
          personCategory: values.personCategory,
          official: {
            mmoWard: values.mmoWard,
          },
          address,
          additionalAddress,
          phones: values.phones,
        };

    const newApplication: CreatedApplication = {
      subjectId: values.subjectId,
      regularityAssistanceId: values.regularityAssistanceId || '',
      regionId: values.regionId,
      projectId: values.projectId,
      responsiblePersonId: values.responsiblePersonId,
      numberOfPerformers: values.numberOfPerformers,
      deadline: values.deadline?.format('YYYY-MM-DD') || '',
      executionDate: values.executionDate?.format('YYYY-MM-DD') || '',
      executionTime: values.executionTime,
      urgent: values.urgent,
      text: values.appText,
      address,
      additionalAddress,
      phones: values.phones?.map(({ id, ...phone }) => phone),
      person,
      appTypeId: values.appTypeId,
    };

    handleCreateApplication(newApplication).finally(() => {
      dispatch(setApplicationSaved(undefined));
      handleSetCreateApplicationState({ isLoadingData: false });
    });
  };

  // Внесенние данных найденного пользователя в форму создания просьбы и в createApplicationState
  const handleSetPersonToForm: HandleSetPersonToForm = (selectedPerson) => {
    createApplicationForm.setFieldsValue({
      firstName: selectedPerson.firstName,
      lastName: selectedPerson.lastName,
      middleName: selectedPerson.middleName,
      withoutMiddleName: !Boolean(selectedPerson.middleName),
      birthDate: moment(selectedPerson.birthDate),
      mmoWard: selectedPerson.official?.mmoWard,
      sex: selectedPerson.sex,
      phones: selectedPerson.phones,
      personCategory: selectedPerson.personCategory ? selectedPerson.personCategory : [],
      unstructuredAddress: selectedPerson.address?.unstructuredAddress,
      subwayStationId: selectedPerson.address?.subwayStationId,
      apartment: selectedPerson.address?.apartment,
      intercomCode: selectedPerson.address?.intercomCode,
      entrance: selectedPerson.address?.entrance,
      floor: selectedPerson.address?.floor,
      regionId: meUser?.regionId,
      additionalAddress: selectedPerson.additionalAddress,
    });
    handleSetCreateApplicationState({
      isFormEditable: true,
      activeModal: undefined,
      selectedPerson,
    });
  };

  // Внесенние данных скопированной просьбы в форму создания просьбы и в createApplicationState
  const handleSetCopyToForm: HandleSetCopyToForm = (selectedPerson) => {
    createApplicationForm.setFieldsValue({
      firstName: selectedPerson.firstName,
      lastName: selectedPerson.lastName,
      middleName: selectedPerson.middleName,
      withoutMiddleName: !Boolean(selectedPerson.middleName),
      birthDate: moment(selectedPerson.birthDate),
      mmoWard: selectedPerson.official?.mmoWard,
      sex: selectedPerson.sex,
      phones: selectedPerson.phones,
      personCategory: selectedPerson.personCategory ? selectedPerson.personCategory : [],

      unstructuredAddress: selectedPerson.address?.unstructuredAddress,
      subwayStationId: selectedPerson.address?.subwayStationId,
      apartment: selectedPerson.address?.apartment,
      intercomCode: selectedPerson.address?.intercomCode,
      entrance: selectedPerson.address?.entrance,
      floor: selectedPerson.address?.floor,

      additionalAddress: selectedPerson?.additionalAddress,

      subjectId: selectedPerson?.subjectId,
      regularityAssistanceId: selectedPerson?.regularityAssistanceId,
      regionId: selectedPerson?.regionId,
      projectId: selectedPerson?.projectId,
      responsiblePersonId: selectedPerson?.responsiblePersonId,
      numberOfPerformers: selectedPerson?.numberOfPerformers,
      deadline: selectedPerson?.deadline ? moment(selectedPerson?.deadline) : null,
      executionDate: selectedPerson?.executionDate ? moment(selectedPerson?.executionDate) : null,
      executionTime: selectedPerson?.executionTime,
      urgent: selectedPerson?.urgent,
      appText: selectedPerson?.text,
      appTypeId: selectedPerson?.appTypeId,
    });
    handleSetCreateApplicationState({
      isFormEditable: true,
      activeModal: undefined,
      selectedPerson,
    });
  };

  // Внесенние закешированных данных  в форму создания просьбы и в createApplicationState
  const handleSetSavedToForm = (savedData) => {
    createApplicationForm.setFieldsValue({
      firstName: savedData.firstName,
      lastName: savedData.lastName,
      middleName: savedData.middleName,
      withoutMiddleName: savedData.withoutMiddleName,
      birthDate: savedData.birthDate ? moment(savedData.birthDate) : null,
      mmoWard: savedData.mmoWard,
      sex: savedData.sex,
      phones: savedData.phones,
      personCategory: savedData.personCategory ? savedData.personCategory : [],

      unstructuredAddress: savedData.unstructuredAddress,
      subwayStationId: savedData.subwayStationId,
      apartment: savedData.apartment,
      intercomCode: savedData.intercomCode,
      entrance: savedData.entrance,
      floor: savedData.floor,

      additionalAddress: savedData?.additionalAddress,

      subjectId: savedData?.subjectId,
      regularityAssistanceId: savedData?.regularityAssistanceId,
      regionId: savedData?.regionId,
      projectId: savedData?.projectId,
      responsiblePersonId: savedData?.responsiblePersonId,
      numberOfPerformers: savedData?.numberOfPerformers,
      deadline: savedData?.deadline ? moment(savedData?.deadline) : null,
      executionDate: savedData?.executionDate ? moment(savedData?.executionDate) : null,
      executionTime: savedData?.executionTime,
      urgent: savedData?.urgent,
      appText: savedData?.appText,
    });
    handleSetCreateApplicationState({
      ...savedData.createApplicationState,
    });
  };

  // Поведение по кнопке "Продолжить": сброс полей формы (кроме ФИО), активация доступности редактирования
  const handleProceed = () => {
    // const fieldsForReset = Object.keys(
    //   createApplicationForm.getFieldsValue()
    // ).filter(
    //   (fieldName) =>
    //     !["lastName", "firstName", "middleName", "withoutMiddleName"].includes(
    //       fieldName
    //     )
    // );
    // createApplicationForm.resetFields(fieldsForReset);
    createApplicationForm.setFieldsValue({ unstructuredAddress: undefined, regionId: meUserFull?.regionId });
    handleSetCreateApplicationState({
      isFormEditable: true,
      activeModal: undefined,
    });
  };

  const onPersonSearch = () => {
    resetCreateApplicationState();
    createApplicationForm
      .validateFields(['lastName', 'firstName', 'middleName'])
      .then(({ lastName, firstName, middleName }) => {
        handleSetCreateApplicationState({ isLoadingData: true });
        const fio = getFio({ firstName, lastName, middleName });
        // TODO Eugene. Ограничить поиск типом анкеты "Подопечный" (id: 4, ФТ: GUI 1.2.b)
        // handleSearchPerson({ fio, personTypeIds: [4] }).then((searchedPersons) => {
        handleSearchPerson({ fio }).then((searchedPersons) => {
          if (searchedPersons.length === 0) {
            handleSetCreateApplicationState({
              searchedPersons,
              isLoadingData: false,
              activeModal: 'noSearchResult',
            });
          } else {
            handleSetCreateApplicationState({
              searchedPersons,
              isLoadingData: false,
              activeModal: 'searchResult',
            });
          }
        });
      });
  };

  const fioValidator = (v, text: string) => {
    if (text === undefined || text === '') {
      return Promise.resolve();
    }
    const permitted = new RegExp(/^[ёЁa-zA-Zа-яА-Я-\/ ]+$/g);
    const mandatory = new RegExp(/[a-zA-Zа-яА-ЯёЁ]/g);
    if (permitted.test(text)) {
      if (mandatory.test(text)) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
    return Promise.reject();
  };

  const renderLeftMiddleForm = () => {
    const onChangeWithoutMiddleName = (e) => {
      if (e.target.checked) createApplicationForm.resetFields(['middleName']);
    };
    const fio = getFio({
      firstName: createApplicationState.selectedPerson?.firstName,
      lastName: createApplicationState.selectedPerson?.lastName,
      middleName: createApplicationState.selectedPerson?.middleName,
    });

    const lockedIcon = (name?: string) => {
      return (
        <>
          {name && <span>{name}</span>}
          {disabledBySelectedPerson && <img src={lockIco} alt="" />}
        </>
      );
    };

    const disabledBySelectedPerson =
      createApplicationState.isFormEditable && Boolean(createApplicationState.selectedPerson);
    return (
      <>
        <Row gutter={20}>
          <Col flex="65%">
            <Form.Item
              label={lockedIcon('Фамилия')}
              name="lastName"
              rules={[
                { required: !disabledBySelectedPerson, message: '' },
                { message: '', validator: fioValidator },
              ]}
            >
              <Input placeholder="Введите фамилию" disabled={disabledBySelectedPerson} maxLength={64} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="birthDate"
              label={lockedIcon('Дата рождения')}
              {...disableStyle}
              rules={[{ required: !disabledBySelectedPerson, message: '' }]}
            >
              <DatePicker
                disabled={!createApplicationState.isFormEditable || disabledBySelectedPerson}
                disabledDate={(date) => date.isAfter(moment())}
                format={displayFormat}
                inputRender={(props) => {
                  return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex="65%">
            <Form.Item
              label={lockedIcon('Имя')}
              name="firstName"
              rules={[
                { required: createApplicationState.isFormEditable && !disabledBySelectedPerson, message: '' },
                { message: '', validator: fioValidator },
              ]}
            >
              <Input placeholder="Введите имя" disabled={disabledBySelectedPerson} maxLength={64} />
            </Form.Item>
          </Col>
          <Col flex="35%">
            <Form.Item name="sex" {...disableStyle} label={lockedIcon('Пол')}>
              <Radio.Group disabled={!createApplicationState.isFormEditable || disabledBySelectedPerson}>
                <Radio value="M">Мужской</Radio>
                <Radio value="F">Женский</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex="65%">
            <Form.Item dependencies={['withoutMiddleName']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const withoutMiddleName = getFieldValue('withoutMiddleName');
                return (
                  <Form.Item
                    label={lockedIcon('Отчество')}
                    name="middleName"
                    rules={[
                      {
                        required:
                          createApplicationState.isFormEditable && !withoutMiddleName && !disabledBySelectedPerson,
                        message: '',
                      },
                      { message: '', validator: fioValidator },
                    ]}
                    className="mb0"
                  >
                    <Input
                      disabled={withoutMiddleName || disabledBySelectedPerson}
                      placeholder={withoutMiddleName ? '' : 'Введите отчество'}
                      maxLength={64}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name="withoutMiddleName" valuePropName="checked">
              <Checkbox
                className="ml5"
                onChange={onChangeWithoutMiddleName}
                disabled={disabledBySelectedPerson || !createApplicationState.isFormEditable}
              >
                Без отчества
              </Checkbox>
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item dependencies={['personCategory']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const personCategoryTitle = getFieldValue('personCategory')
                  ?.map((categoryId) => personCategory.find((item) => item.id === categoryId)?.name)
                  .join('\n');
                return (
                  <div title={personCategoryTitle}>
                    <Form.Item name="personCategory" label={lockedIcon('Категория')} {...disableStyle}>
                      <Select
                        placeholder="Выберите из списка"
                        disabled={!createApplicationState.isFormEditable || disabledBySelectedPerson}
                        mode="multiple"
                        maxTagCount={1}
                      >
                        {personCategory
                          .filter((el) => !el.deleted)
                          .map(({ id, name }) => (
                            <Select.Option key={id} value={id}>
                              <span>{name}</span>
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col>
            <Button
              onClick={onPersonSearch}
              disabled={createApplicationState.isFormEditable || disabledBySelectedPerson}
            >
              Поиск анкеты
            </Button>
          </Col>
          {createApplicationState.selectedPerson && (
            <Col className="font-roboto pt6">
              Анкета подопечного:&nbsp;
              <Link
                to={`/files/${createApplicationState.selectedPerson.id}/general`}
                className="font-size-14"
                target="_blank"
              >
                {fio}
              </Link>
            </Col>
          )}
        </Row>
        <Row>
          <h3 className="mb5 mt20 text-bold" {...disableStyle}>
            Служебная информация
          </h3>
          <Divider className="thin-divider mt0 mb10" {...disableStyle} />
          <Form.Item name="mmoWard" valuePropName="checked" {...disableStyle}>
            <Checkbox disabled={!createApplicationState.isFormEditable || disabledBySelectedPerson}>
              <span style={{ fontSize: '16px' }}>Подопечный ММО {lockedIcon()}</span>
            </Checkbox>
          </Form.Item>
        </Row>
      </>
    );
  };

  const nameAddress = { 0: 'Б', 1: 'В' };

  const renderRightMiddleForm = () => (
    <div style={{ maxHeight: 385, overflowY: 'auto', overflowX: 'hidden' }}>
      <div>
        <h3 className="mb5 text-bold" {...disableStyle}>
          <Row>Адрес А</Row>
        </h3>

        <Divider className="thin-divider mt0 mb10" {...disableStyle} />

        <Row gutter={20}>
          <Col flex="65%">
            <SuggestInput
              name="unstructuredAddress"
              style={disableStyle.style}
              onChange={onChangeAddress}
              disabled={!createApplicationState.isFormEditable}
              required
            />
          </Col>
          <Col flex="auto">
            <Form.Item name="subwayStationId" label="Метро" {...disableStyle}>
              <Select
                placeholder="Выберите из списка"
                disabled={!createApplicationState.isFormEditable}
                showSearch
                filterOption={selectFilterOption}
                allowClear
                onSelect={onChangeAddress}
              >
                {subwayStation
                  .filter((el) => !el.deleted)
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col flex="25%">
            <Form.Item name="apartment" label="Квартира" {...disableStyle} rules={[{ required: true, message: '' }]}>
              <Input placeholder="Номер" disabled={!createApplicationState.isFormEditable} onChange={onChangeAddress} />
            </Form.Item>
          </Col>
          <Col flex="25%">
            <Form.Item name="intercomCode" label="Код домофона" {...disableStyle}>
              <Input
                placeholder="Код"
                disabled={!createApplicationState.isFormEditable}
                onChange={onChangeAddress}
                maxLength={15}
              />
            </Form.Item>
          </Col>
          <Col flex="25%">
            <Form.Item name="entrance" label="Подъезд" {...disableStyle}>
              <Input
                placeholder="Подъезд"
                disabled={!createApplicationState.isFormEditable}
                onChange={onChangeAddress}
              />
            </Form.Item>
          </Col>
          <Col flex="25%">
            <Form.Item name="floor" label="Этаж" {...disableStyle}>
              <Input placeholder="Этаж" disabled={!createApplicationState.isFormEditable} onChange={onChangeAddress} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Form.List name="additionalAddress">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <h3 className="mb5 text-bold" {...disableStyle}>
                    <Row>
                      Адрес{` ${nameAddress[index]}`}
                      <DeleteIcon
                        className="mt1"
                        title="Удалить Адрес"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          createApplicationState.isFormEditable && remove(field.name);
                        }}
                      />
                    </Row>
                  </h3>
                  <Divider className="thin-divider mt0 mb10" {...disableStyle} />
                  <Row gutter={20}>
                    <Col flex="65%">
                      <SuggestInput
                        index={index}
                        name="unstructuredAddress"
                        style={disableStyle.style}
                        onChange={onChangeAddress}
                        disabled={!createApplicationState.isFormEditable}
                        required
                      />
                    </Col>
                    <Col flex="auto">
                      <Form.Item name={[index, 'subwayStationId']} label="Метро" {...disableStyle}>
                        <Select
                          placeholder="Выберите из списка"
                          disabled={!createApplicationState.isFormEditable}
                          showSearch
                          filterOption={selectFilterOption}
                          allowClear
                          onSelect={onChangeAddress}
                        >
                          {subwayStation
                            .filter((el) => !el.deleted)
                            .map(({ id, name }) => (
                              <Select.Option key={id} value={id}>
                                {name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row wrap={false} gutter={30}>
                    <Col flex="25%">
                      <Form.Item
                        name={[index, 'apartment']}
                        label="Квартира"
                        {...disableStyle}
                        rules={[{ required: true, message: '' }]}
                      >
                        <Input
                          placeholder="Номер"
                          disabled={!createApplicationState.isFormEditable}
                          onChange={onChangeAddress}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="25%">
                      <Form.Item name={[index, 'intercomCode']} label="Код домофона" {...disableStyle}>
                        <Input
                          placeholder="Код"
                          disabled={!createApplicationState.isFormEditable}
                          onChange={onChangeAddress}
                          maxLength={15}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="25%">
                      <Form.Item name={[index, 'entrance']} label="Подъезд" {...disableStyle}>
                        <Input
                          placeholder="Подъезд"
                          disabled={!createApplicationState.isFormEditable}
                          onChange={onChangeAddress}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="25%">
                      <Form.Item name={[index, 'floor']} label="Этаж" {...disableStyle}>
                        <Input
                          placeholder="Этаж"
                          disabled={!createApplicationState.isFormEditable}
                          onChange={onChangeAddress}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
              {fields.length < 1 && (
                <Row>
                  <Button
                    type="primary"
                    className="create-application__addAddressBtn mb10"
                    icon={<PlusIcon />}
                    onClick={() => {
                      FireBaseService.createLogEventFireBase('Событие. Добавить адрес Б');
                      add();
                    }}
                    disabled={!createApplicationState.isFormEditable}
                    {...disableStyle}
                  >
                    Добавить адрес
                  </Button>
                </Row>
              )}
            </div>
          );
        }}
      </Form.List>

      <Row>
        <Col {...disableStyle} flex="auto">
          <PhonesTable
            isDisabled={!createApplicationState.isFormEditable}
            onChange={onChangePhones}
            isValidMainPhone={false}
          />
        </Col>
      </Row>
    </div>
  );

  const renderBottomForm = () => {
    const onSelectRegionId = () => {
      createApplicationForm.resetFields(['projectId', 'responsiblePersonId']);
    };
    const onSelectProjectId = (projectId: number) => {
      loadResponsibles({ projectIds: [projectId], personStatusIds: [3] });
      createApplicationForm.resetFields(['responsiblePersonId']);
    };
    return (
      <>
        <Row gutter={20}>
          <Col span={3}>
            <Form.Item name="appTypeId" label="Тип просьбы" rules={[{ required: true, message: '' }]} {...disableStyle}>
              <Select placeholder="Выберите из списка" disabled={!createApplicationState.isFormEditable}>
                {appType?.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="subjectId" label="Вид помощи" rules={[{ required: true, message: '' }]} {...disableStyle}>
              <Select placeholder="Выберите из списка" disabled={!createApplicationState.isFormEditable}>
                {[...appSubject]
                  .filter((el) => !el.deleted)
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      <div
                        className="display-flex"
                        style={{ justifyContent: 'center', alignItems: 'center' }}
                        title={name}
                      >
                        <div
                          className="display-flex icon-application-select"
                          style={{ alignItems: 'center', minWidth: 20, maxWidth: 20 }}
                        >
                          {findDynamicSVGReactElement('app_subject', 'help', 'iconName', id)}
                        </div>
                        <span className="ml5 ant-select-item-option-content">{name}</span>
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="regularityAssistanceId" label="Регулярность помощи" {...disableStyle}>
              <Select placeholder="Выберите из списка" disabled={!createApplicationState.isFormEditable} allowClear>
                {regularityOfAssistance
                  .filter((el) => !el.deleted)
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="regionId" label="Субъект РФ" rules={[{ required: true, message: '' }]} {...disableStyle}>
              <Select
                placeholder="Выберите из списка"
                disabled={!createApplicationState.isFormEditable || !hasAccess(['APPLICATIONS.READ.ALLREGIONS'])}
                onSelect={onSelectRegionId}
              >
                {region
                  .filter((el) => !el.deleted)
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item dependencies={['regionId']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const regionId = getFieldValue('regionId');
                return (
                  <Form.Item
                    name="projectId"
                    label="Проект"
                    rules={[{ required: true, message: '' }]}
                    {...disableStyle}
                  >
                    <Select
                      placeholder={regionId ? 'Выберите из списка' : 'Выберите субъект РФ'}
                      disabled={!regionId || !createApplicationState.isFormEditable}
                      onSelect={onSelectProjectId}
                      loading={checkIsLoading(projectsLoading)}
                    >
                      {Array.isArray(projects) &&
                        projects
                          .filter((project) => project.regionId === regionId)
                          .filter((project) => project.projectTypeId === ProjectTypes.Активные)
                          .map(({ id, title }) => (
                            <Select.Option key={id} value={id}>
                              {title}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item dependencies={['regionId', 'projectId']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const projectId = getFieldValue('projectId');
                return (
                  <Form.Item name="responsiblePersonId" label="Ответственный" {...disableStyle}>
                    <Select
                      placeholder={projectId ? 'Выберите из списка' : 'Выберите проект'}
                      disabled={!projectId || !createApplicationState.isFormEditable}
                      allowClear
                      loading={checkIsLoading(responsiblesLoading)}
                    >
                      {Array.isArray(responsibles) &&
                        responsibles.map(({ id, firstName, lastName, middleName }) => {
                          const fio = getFio({
                            firstName,
                            lastName,
                            middleName,
                          });
                          return (
                            <Select.Option key={id} value={id}>
                              {fio}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item dependencies={['appTypeId']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const appTypeId = getFieldValue('appTypeId');
                return (
                  <Form.Item name="numberOfPerformers" label="Число исполнителей" {...disableStyle}>
                    <Input
                      placeholder="Число"
                      disabled={!createApplicationState.isFormEditable || appTypeId === 2}
                      type="number"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item name="appText" label="Просьба" className="create-application__dscTextArea" {...disableStyle}>
              <Input.TextArea placeholder="Текст просьбы" disabled={!createApplicationState.isFormEditable} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="deadline" label="Срок завершения" {...disableStyle}>
                  <DatePicker
                    disabled={!createApplicationState.isFormEditable}
                    disabledDate={(date) => date.isBefore(moment(), 'day')}
                    format={displayFormat}
                    inputRender={(props) => {
                      return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="urgent" valuePropName="checked" className="mt25">
                  <Checkbox disabled={!createApplicationState.isFormEditable}>
                    <span style={{ fontSize: '16px' }}>Срочная просьба</span>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="executionDate" label="Дата исполнения" {...disableStyle}>
                  <DatePicker
                    disabled={!createApplicationState.isFormEditable}
                    disabledDate={(date) => date.isBefore(moment(), 'day')}
                    format={displayFormat}
                    inputRender={(props) => {
                      return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="executionTime" label=" " {...disableStyle}>
                  <Input
                    placeholder="Укажите время исполнения"
                    disabled={!createApplicationState.isFormEditable}
                    maxLength={18}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderNoSearchResultPersonModal = () => {
    const closeModal = () => {
      handleSetCreateApplicationState({ activeModal: '' });
    };

    return (
      <Modal
        visible={createApplicationState.activeModal === 'noSearchResult'}
        width="650px"
        footer={null}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
      >
        <span className="font-roboto">При заполнении просьбы не были найдены совпадения с Подопечными.</span>
        <Row>
          <Col className="ml-auto mr-auto mt17">
            <Button type="primary" onClick={handleProceed} style={{ width: '216px' }}>
              Создать новую анкету
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderPersonInPrayListModal = () => {
    const closeModal = () => {
      handleSetCreateApplicationState({ activeModal: 'searchResult' });
    };

    return (
      <Modal
        visible={createApplicationState.activeModal === 'inPrayList'}
        width="588px"
        footer={null}
        centered
        closable={false}
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
        onCancel={closeModal}
      >
        <span className="font-roboto">Подопечный находится в списке молитв, создание просьбы запрещено.</span>
        <Row gutter={20} className="mt17">
          <Col className="ml-auto mr-auto">
            <Button onClick={closeModal} style={{ width: '160px' }}>
              Вернуться назад
            </Button>
          </Col>
          {/* <Col className="mr-auto">
            <Button type="primary" onClick={handleProceed}>
              Продолжить
            </Button>
          </Col> */}
        </Row>
      </Modal>
    );
  };

  const renderIsAddressChangedModal = () => {
    const closeModal = () => {
      handleSetCreateApplicationState({ activeModal: '' });
    };

    return (
      <Modal
        visible={createApplicationState.activeModal === 'isAddressChanged'}
        width="600px"
        footer={null}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
      >
        <span className="font-roboto">
          Вы редактируете адрес просьбы. Изменение адреса просьбы не приведет к изменению адреса в связанной анкете
          подопечного. Адрес анкеты можно отредактировать в карточке анкеты на вкладке «Картотека»
        </span>
        <Row>
          <Col className="ml-auto mr-auto mt17">
            <Button type="primary" onClick={closeModal} style={{ width: '216px' }}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderIsPhonesChangedModal = () => {
    const closeModal = () => {
      handleSetCreateApplicationState({ activeModal: '' });
    };

    return (
      <Modal
        visible={createApplicationState.activeModal === 'isPhoneChanged'}
        width="600px"
        footer={null}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
      >
        <span className="font-roboto">
          Вы редактируете контактный телефон просьбы. Изменение контактного телефона просьбы не приведет к изменению
          телефона в связанной анкете подопечного. Телефон в анкете можно отредактировать в карточке анкеты на вкладке
          «Картотека»
        </span>
        <Row>
          <Col className="ml-auto mr-auto mt17">
            <Button type="primary" onClick={closeModal} style={{ width: '216px' }}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  // Проверка на копию
  useEffect(() => {
    // Если есть информация о копировании, то заполняем форму
    if (applicationCopy && applicationId) {
      loadResponsibles({ projectIds: [applicationCopy.projectId] });
      handleSetCopyToForm(applicationCopy);
    }
    // Если обновили страничку
    if (!applicationCopy && applicationId) {
      navigate(`/applications/list/${applicationId}/main`, { replace: true });
    }
    // Если перешли в обычное создание
    if (applicationCopy && !applicationId) {
      createApplicationForm.resetFields();
      setCreateApplicationState(defaultCreateApplicationState);
    }
  }, [applicationCopy, applicationId]);

  // Проверка на сохранение раннее вводимых данных
  useEffect(() => {
    // Если нет информацияи о копировании, но есть информация о раннее сохранённых данных то заполняем форму
    if (!applicationCopy && !applicationId && applicationSaved) {
      loadResponsibles({ projectIds: [applicationSaved.projectId] });
      handleSetSavedToForm(applicationSaved);
    }
  }, [applicationCopy, applicationId]);

  const onCancel = () => {
    dispatch(setApplicationSaved(undefined));
    navigate(applicationCopy && applicationId ? `/applications/list/${applicationId}/main` : '/applications/list', {
      replace: !!(applicationCopy && applicationId),
    });
  };

  const handleOpenCancelModal = () => {
    // Проверка на сохранение введённых данных, только если это не копирование
    if (!applicationCopy && !applicationId) {
      const values = createApplicationForm.getFieldsValue();

      const changedFields: string[] = [];

      // Проверка на то есть ли введённые данные
      Object.entries(values).map(([key, value]) => {
        if (value) {
          changedFields.push(key);
        }
      });

      // Если есть введённые данные, то спрашиваем, сохранить ли их
      if (changedFields.length > 0) {
        setIsOpenCancelModal(true);
      } else {
        onCancel();
      }
    } else {
      onCancel();
    }
  };

  const renderIsCancelModal = () => {
    const onSaved = () => {
      const values = createApplicationForm.getFieldsValue();

      dispatch(setApplicationSaved({ ...values, createApplicationState: createApplicationState }));

      navigate(applicationCopy && applicationId ? `/applications/list/${applicationId}/main` : '/applications/list', {
        replace: !!(applicationCopy && applicationId),
      });
    };

    return (
      <Modal
        visible={isOpenCancelModal}
        footer={false}
        closeIcon={false}
        closable={false}
        width="480px"
        bodyStyle={{ width: '480px' }}
      >
        Вы заполнили часть данных. Чтобы продолжить работу позднее, нажмите "Сохранить данные". <br />
        Если заполненные данные Вам не понадобятся, нажмите "Выйти без сохранения". <br /> <br />
        <span style={{ color: 'red' }}>*</span>Данные сохраняются до обновления страницы.
        <Row className="mt20" justify="space-between">
          <Button onClick={onCancel}>Выйти без сохранения</Button>

          <Button type="primary" onClick={onSaved}>
            Сохранить данные
          </Button>
        </Row>
      </Modal>
    );
  };

  return (
    <Spin spinning={createApplicationState.isLoadingData}>
      <div className="create-application ml20 mr20 mt20">
        <Row>
          <Col flex="31.5%">
            <h2 className="mb6 text-bold">{`Создание новой просьбы${applicationId ? ' - копия' : ''}`}</h2>
            <Divider className="thin-divider mt0" />
          </Col>
        </Row>

        <Form
          form={createApplicationForm}
          layout="vertical"
          onFinish={onFormSubmit}
          initialValues={{ urgent: false, address: [{}] }}
        >
          <Row gutter={20} wrap={false}>
            <Col flex="49.5%">
              <h3 className="mb5 text-bold">Личные данные подопечного</h3>
              <Divider className="thin-divider mt0 mb10" />
              {renderLeftMiddleForm()}
            </Col>
            <Col flex="49.5%">
              <h3 className="mb5 text-bold" {...disableStyle}>
                Адрес и контактные данные
              </h3>
              <Divider className="thin-divider mt0 mb10" {...disableStyle} />
              {renderRightMiddleForm()}
            </Col>
          </Row>
          <h3 className="mb5 text-bold mt20" {...disableStyle}>
            Обработка просьбы
          </h3>
          <Divider className="thin-divider mt0 mb10" {...disableStyle} />
          {renderBottomForm()}
          <Row className="mt20">
            <Button className="mr20" onClick={handleOpenCancelModal}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Row>
        </Form>
        {renderNoSearchResultPersonModal()}
        <SearchResultPersonModal
          handleProceed={handleProceed}
          handleSetPersonToForm={handleSetPersonToForm}
          handleSetCreateApplicationState={handleSetCreateApplicationState}
          createApplicationState={createApplicationState}
        />
        {renderPersonInPrayListModal()}
        {renderIsAddressChangedModal()}
        {renderIsPhonesChangedModal()}
        {renderIsCancelModal()}
      </div>
    </Spin>
  );
};
