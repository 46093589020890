import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  getNotificationsRoute,
  getUnreadNotificationRoute,
  markAsReadNotificationRoute,
} from '../../../lib/routes/notifications';
import { message } from 'antd';

type Notification = {
  id: number;
  tenantId: number;
  userId: number;
  createDate: string;
  payload: string;
};

interface InitialState {
  unreadNotifications: Notification[];
  unreadNotificationsLoading: RequestStatuses;
  allNotifications: Notification[];
  allNotificationsLoading: RequestStatuses;
  totalNotifications: number;
  unreadNotificationsCount: number;
  unreadNotificationsCountLoading: RequestStatuses;
}

const initialState: InitialState = {
  unreadNotifications: [],
  unreadNotificationsLoading: requestStatusCodes.idle,
  allNotifications: [],
  allNotificationsLoading: requestStatusCodes.idle,
  totalNotifications: 0,
  unreadNotificationsCount: 0,
  unreadNotificationsCountLoading: requestStatusCodes.idle,
};

const getUnreadNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({
    page,
    size,
    userId,
    searchUnread = true,
  }: {
    page: any;
    size: any;
    userId: number;
    searchUnread?: boolean;
  }) => {
    const response = axios.post(getNotificationsRoute(page, size), {
      userId,
      searchUnread,
    });
    const { data } = await response;
    return { notifications: data.content, searchUnread };
  }
);

const getAllNotifications = createAsyncThunk(
  'notifications/getAllNotifications',
  async ({
    page,
    size,
    userId,
    searchUnread = false,
  }: {
    page: any;
    size: any;
    userId: number;
    searchUnread?: boolean;
  }) => {
    const response = axios.post(getNotificationsRoute(page, size), {
      userId,
      searchUnread,
    });
    const { data } = await response;
    return {
      notifications: data.content,
      searchUnread,
      total: data.totalElements,
    };
  }
);

const getUnreadNotificationsCount = createAsyncThunk('notifications/getUnreadNotificationsCount', async () => {
  const response = axios.post(getUnreadNotificationRoute());
  const { data } = await response;
  return data.count;
});

const markAsReadNotification = createAsyncThunk(
  'notifications/markAsReadNotification',
  async ({ id }: { id: number }) => {
    const response = axios.post(markAsReadNotificationRoute(id));
    const { data } = await response;
    return {
      count: data.count,
      id,
    };
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNewNotification(state, action: PayloadAction<any>) {
      state.unreadNotifications.unshift(action.payload);
      state.unreadNotificationsCount += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        // const typeOfDirectory = action.payload?.searchUnread ? "unreadNotifications" : "allNotifications"
        // const typeOfDirectoryLoading = action.payload?.searchUnread ? "unreadNotificationsLoading" : "allNotificationsLoading"
        state.unreadNotifications = action.payload.notifications;
        state.unreadNotificationsLoading = requestStatusCodes.fulfilled;
      })
      .addCase(getUnreadNotifications.pending, (state, action: PayloadAction<any>) => {
        // const typeOfDirectoryLoading = action.payload?.searchUnread ? "unreadNotificationsLoading" : "allNotificationsLoading"
        state.unreadNotificationsLoading = requestStatusCodes.pending;
      })
      .addCase(getUnreadNotifications.rejected, (state, action: PayloadAction<any>) => {
        // const typeOfDirectoryLoading = action.payload?.searchUnread ? "unreadNotificationsLoading" : "allNotificationsLoading"
        state.unreadNotificationsLoading = requestStatusCodes.rejected;
      })
      .addCase(getAllNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        state.allNotifications = action.payload.notifications;
        state.allNotificationsLoading = requestStatusCodes.fulfilled;
        state.totalNotifications = action.payload.total;
      })
      .addCase(getAllNotifications.pending, (state, action: PayloadAction<any>) => {
        state.allNotificationsLoading = requestStatusCodes.pending;
      })
      .addCase(getAllNotifications.rejected, (state, action: PayloadAction<any>) => {
        state.allNotificationsLoading = requestStatusCodes.rejected;
      })
      .addCase(getUnreadNotificationsCount.fulfilled, (state, action: PayloadAction<number>) => {
        state.unreadNotificationsCount = action.payload;
        state.unreadNotificationsCountLoading = requestStatusCodes.fulfilled;
      })
      .addCase(getUnreadNotificationsCount.pending, (state) => {
        state.unreadNotificationsCountLoading = requestStatusCodes.pending;
      })
      .addCase(getUnreadNotificationsCount.rejected, (state) => {
        state.unreadNotificationsCountLoading = requestStatusCodes.rejected;
      })
      .addCase(markAsReadNotification.fulfilled, (state, action: PayloadAction<object>) => {
        // @ts-ignore
        state.unreadNotificationsCount = Number(action.payload.count);
        state.unreadNotifications = state.unreadNotifications.filter(
          // @ts-ignore
          (item) => item.id !== action.payload?.id
        );
      })
      .addCase(markAsReadNotification.rejected, (state, e) => {
        console.error(e);
        message.error('Ошибка');
      });
  },
});

const { actions, reducer } = notificationsSlice;
const { pushNewNotification } = actions;

export {
  reducer,
  getUnreadNotifications,
  getUnreadNotificationsCount,
  pushNewNotification,
  markAsReadNotification,
  getAllNotifications,
};
